// React
import { useState, useRef, useEffect } from 'react';
import { Turnstile } from '@marsidev/react-turnstile';

// Custom Functions & Bootstrap
import {
  errorNotification,
  listOfCountries,
  successNotification,
} from '../../Helpers/utils.js';
import { Row, Col, Container, Collapse, Form } from 'react-bootstrap';

// MailChecker
import MailChecker from 'mailchecker';

import JSConfetti from 'js-confetti';

// Local Storage
const country = localStorage.getItem('detected-country');
const local = localStorage.getItem('selected-country') ?? country;

export default function Footer() {
  const reference = useRef();
  const [email, setEmail] = useState();
  const [token, setToken] = useState();
  const [openSections, setOpenSections] = useState({
    people: false,
    movies: false,
    shows: false,
    account: false,
    help: false,
  });

  const canvasRef = useRef();
  const confettiRef = useRef();

  const handleToggle = (section) => {
    setOpenSections((prevState) => ({
      ...prevState,
      [section]: !prevState[section],
    }));
  };

  useEffect(() => {
    confettiRef.current = new JSConfetti({ canvas: canvasRef.current });
  }, []);

  async function handleSubmit(e) {
    e.preventDefault();

    const response = await fetch(`https://turnstile.skuflic.net?rt=${token}`, {
      cache: 'no-cache',
    })
      .then((response) => response.json())
      .then((data) => {
        if (!data.success) {
          reference.current?.reset();
        }
        return data.success;
      });

    if (MailChecker.isValid(email) && response === true) {
      try {
        fetch(`https://subscribe.skuflic.net?user=${email}`, {
          cache: 'no-cache',
        })
          .then((response) => response.json())
          .then((data) => data.job_id);
        successNotification('Success! Stay tuned for updates.');
        document.getElementById('email').value = null;
        confettiRef.current
          .addConfetti({
            confettiRadius: 5,
            confettiNumber: 100,
            emojis: ['🦄', '🎉', '🥳', '✨', '🌸'],
          })
          .then(() => confettiRef.current.clearCanvas());
      } catch (e) {
        console.error(e);
      }
    } else {
      errorNotification('Your email address is invalid.');
    }
  }

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://static.skuflic.com/cookies/cookieconsent.js';
    script.type = 'module';
    script.defer = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const sections = [
    {
      title: 'TV Shows',
      key: 'shows',
      links: [
        { href: '/trending/tv', label: 'Trending' },
        { href: '/popular/tv', label: 'Popular' },
        { href: '/top-rated/tv', label: 'Top rated' },
        { href: '/airing-today/tv', label: 'Airing today' },
        { href: '/on_the_air/tv', label: 'On the air' },
      ],
      genres: [
        {
          href: '/discover/tv/genre/action-and-adventure',
          label: 'Action & Adventure',
        },
        { href: '/discover/tv/genre/comedy', label: 'Comedy' },
        { href: '/discover/tv/genre/crime', label: 'Crime' },
        { href: '/discover/tv/genre/documentaries', label: 'Documentary' },
        { href: '/discover/tv/genre/drama', label: 'Drama' },
        { href: '/discover/tv/genre/family', label: 'Family' },
        { href: '/discover/tv/genre/mystery', label: 'Mystery' },
        { href: '/discover/tv/genre/reality', label: 'Reality' },
        { href: '/discover/tv/genre/romance', label: 'Romance' },
        {
          href: '/discover/tv/genre/science-fiction-and-fantasy',
          label: 'Sci-Fi & Fantasy',
        },
      ],
      networks: [
        { href: '/discover/tv/network/amc', label: 'AMC+' },
        { href: '/discover/tv/network/apple', label: 'Apple TV+' },
        { href: '/discover/tv/network/disney', label: 'Disney+' },
        { href: '/discover/tv/network/fx', label: 'FX' },
        { href: '/discover/tv/network/hbo', label: 'HBO' },
        { href: '/discover/tv/network/hulu', label: 'Hulu' },
        { href: '/discover/tv/network/netflix', label: 'Netflix' },
        { href: '/discover/tv/network/paramount', label: 'Paramount+' },
        { href: '/discover/tv/network/showtime', label: 'Showtime' },
      ],
    },
    {
      title: 'Movies',
      key: 'movies',
      links: [
        { href: '/trending/movies', label: 'Trending' },
        { href: '/popular/movies', label: 'Popular' },
        { href: '/top-rated/movies', label: 'Top rated' },
        { href: '/upcoming/movies', label: 'Upcoming' },
        { href: '/now-playing/movies', label: 'In theatres' },
      ],
      genres: [
        { href: '/discover/movies/genre/action', label: 'Action' },
        { href: '/discover/movies/genre/adventure', label: 'Adventure' },
        { href: '/discover/movies/genre/comedy', label: 'Comedy' },
        { href: '/discover/movies/genre/crime', label: 'Crime' },
        { href: '/discover/movies/genre/documentaries', label: 'Documentary' },
        { href: '/discover/movies/genre/drama', label: 'Drama' },
        { href: '/discover/movies/genre/horror', label: 'Horror' },
        { href: '/discover/movies/genre/mystery', label: 'Mystery' },
        { href: '/discover/movies/genre/romance', label: 'Romance' },
        {
          href: '/discover/movies/genre/science-fiction',
          label: 'Science Fiction',
        },
        { href: '/discover/movies/genre/thriller', label: 'Thriller' },
      ],
    },
    {
      title: 'People',
      key: 'people',
      links: [
        { href: '/trending/people', label: 'Trending' },
        { href: '/popular/people', label: 'Popular' },
      ],
    },
    {
      title: 'Account',
      key: 'account',
      links: [
        { href: '/account', label: 'My Account' },
        { href: '/account/favorites', label: 'Favorites' },
      ],
    },
    {
      title: 'Help',
      key: 'help',
      links: [
        { href: '/about', label: 'About Reel', external: true },
        {
          href: 'https://status.skuflic.com',
          label: 'System Status',
          external: true,
        },
        { href: '/support', label: 'Get Help', external: true },
        {
          href: 'https://go.skuflic.com/report-a-bug',
          label: 'Report a Bug',
          external: true,
        },
      ],
      legal: [
        {
          label: 'Cookie Settings',
          type: 'button',
          dataCc: 'show-preferencesModal',
        },
        {
          href: '/servicesagreement',
          label: 'Services Agreement',
          external: true,
        },
        { href: '/privacy', label: 'Privacy Policy', external: true },
      ],
      filterExplicit: [
        { href: '/safesearch', label: 'Manage Search Preferences' },
      ],
    },
  ];

  return (
    <Container className='footer'>
      <Row>
        {sections.map((section) => (
          <Col key={section.key}>
            <h5
              onClick={() => handleToggle(section.key)}
              aria-controls={`Collapse ${section.title}`}
              aria-expanded={openSections[section.key]}
              role='button'>
              {section.title}
              <span className='material-symbols-outlined'>
                {' '}
                keyboard_arrow_down{' '}
              </span>
            </h5>
            <Collapse in={openSections[section.key]}>
              <ul>
                {section.links.map((link) => (
                  <li key={link.href}>
                    <a
                      href={link.href}
                      target={link.external ? '_blank' : '_self'}
                      rel={link.external ? 'noreferrer' : ''}>
                      {link.label}
                    </a>
                  </li>
                ))}
                {section.genres && (
                  <>
                    <h4>By genre</h4>
                    {section.genres.map((genre) => (
                      <li key={genre.href}>
                        <a href={genre.href}>{genre.label}</a>
                      </li>
                    ))}
                  </>
                )}
                {section.networks && (
                  <>
                    <h4>By network</h4>
                    {section.networks.map((network) => (
                      <li key={network.href}>
                        <a href={network.href}>{network.label}</a>
                      </li>
                    ))}
                  </>
                )}
                {section.legal && (
                  <>
                    <h4>Legal</h4>
                    {section.legal.map((legal) =>
                      legal.type === 'button' ? (
                        <li key={legal.label}>
                          <button data-cc={legal.dataCc} type='button'>
                            {legal.label}
                          </button>
                        </li>
                      ) : (
                        <li key={legal.href}>
                          <a
                            href={legal.href}
                            target={legal.external ? '_blank' : '_self'}
                            rel={legal.external ? 'noreferrer' : ''}>
                            {legal.label}
                          </a>
                        </li>
                      )
                    )}
                  </>
                )}
                {section.filterExplicit && (
                  <>
                    <h4>Filter Explicit Results</h4>
                    {section.filterExplicit.map((filter) => (
                      <li key={filter.href}>
                        <a href={filter.href}>{filter.label}</a>
                      </li>
                    ))}
                  </>
                )}
              </ul>
            </Collapse>
          </Col>
        ))}
        <Form>
          <div>
            <h4>Let's stay in touch</h4>
            <input
              type='email'
              id='email'
              aria-describedby='Enter Email Address'
              placeholder='Enter Email'
              required
              onChange={(e) => setEmail(e.target.value)}
            />
            <button className='custom-button' onClick={handleSubmit}>
              Submit
            </button>
          </div>
          <p id='help'>
            By clicking the submit button, you agree to Reel using your email
            address to send you marketing communications, updates, special
            offers and other information about Reel. You can unsubscribe at any
            time. For more information on how we handle your personal data,
            please see our{' '}
            <a href='/privacy' target='_blank' rel='noreferrer'>
              Privacy Policy
            </a>
            .
          </p>
          <Turnstile
            siteKey='0x4AAAAAAAM_D35F4o3pwJXW'
            className='turnstile'
            id='submit-form'
            options={{
              action: 'submit-form',
              theme: 'dark',
              size: 'normal',
              language: 'en',
              refreshExpired: 'auto',
              appearance: 'interaction-only',
            }}
            onSuccess={setToken}
            ref={reference}
          />
        </Form>
      </Row>
      <div className='disclaimer'>
        <a href='/country-selector' rel='noreferrer' className='country'>
          <span className='material-symbols-outlined'>south_america</span>
          <span className='uppercase'>
            {local === null ? 'Select Your Country' : listOfCountries[local]}
          </span>
        </a>
        TM and Copyright &copy; {new Date().getFullYear()}{' '}
        <a href='https://www.skuflic.com' target='_blank' rel='noreferrer'>
          Skuflic.com.
        </a>{' '}
        Reel and all related logos are trademarks of Skuflic.com. Other product
        and company names mentioned herein may be trademarks of their respective
        companies. Images may be subject to copyright. This product uses the
        TMDb API but is not endorsed or certified by TMDb. Some content may be
        inappropriate for minors. Viewer discretion is advised. Some information
        may be inaccurate or out of date. Subscription(s) are required to access
        certain content. Reel is hosted with no carbon emissions, powered by{' '}
        <span className='green'>renewable</span> energy.
      </div>
    </Container>
  );
}
