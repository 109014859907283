// React Core
import { useReducer } from 'react';
import { createRoot } from 'react-dom/client';
import {
  BrowserRouter,
  Routes,
  Route,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';

// CSS
import 'react-toastify/dist/ReactToastify.css';
import './Styles/App.scss';

// Main Components & Assets
import App from './App';
import {
  MovieDetails,
  CustomNavbar,
  TVShowDetails,
  PeopleDetails,
  Episodes,
  TrendingList,
  Country,
  SafeSearch,
  Footer,
  Login,
  Register,
  Account,
  PasswordReset,
  Favorites,
  Collection,
} from './Components';

import { getCookie } from './Helpers/utils';

import { getWebInstrumentations, initializeFaro } from '@grafana/faro-react';
import { TracingInstrumentation } from '@grafana/faro-web-tracing';

initializeFaro({
  url: 'https://faro-collector-prod-eu-west-2.grafana.net/collect/ae7a08f48de7533136c2513d1411f797',
  app: {
    name: 'Reel',
    version: '1.0.0',
    environment: 'production',
  },

  instrumentations: [
    // Mandatory, omits default instrumentations otherwise.
    ...getWebInstrumentations(),

    // Tracing package to get end-to-end visibility for HTTP requests.
    new TracingInstrumentation(),

    // React integration for React applications.
  ],
});

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);
const session = localStorage.getItem('session');
const country =
  localStorage.getItem('detected-country') ||
  localStorage.getItem('selected-country');

// Arrow 'back to top'
function topFunction() {
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
}

if (country == null) {
  (async function getCountry() {
    try {
      const response = await fetch(
        'https://ipinfo.io/json?token=db4fa62315187a'
      );
      const data = await response.json();
      window.localStorage.setItem('detected-country', data.country);
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
    }
  })();
}

// Notification Banner
const Banner = () => {
  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  function setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    let expires = 'expires=' + d.toUTCString();
    document.cookie = cname + '=' + cvalue + '; ' + expires + '; path=/';
    forceUpdate();
  }

  let closed = getCookie('top-bar');

  if (closed !== 'closed') {
    return (
      <div className='top-bar' id='top-bar-hide'>
        <p>
          <strong>✨ Get ready for an epic upgrade!</strong> Reel is leveling up
          with amazing new features and exciting improvements. Please note that
          registration is currently disabled as we are updating the entire Reel
          experience.
        </p>
        <span
          className='material-symbols-outlined close'
          onClick={() => {
            setCookie('top-bar', 'closed', 7);
          }}>
          {' '}
          close{' '}
        </span>
      </div>
    );
  }
};

// Creating User Session for Rating Content
if (session == null) {
  fetch(`https://api.skuflic.net/authentication/guest_session/new`)
    .then((data) => data.json())
    .then((data) =>
      window.localStorage.setItem('session', data.guest_session_id)
    );
}

root.render(
  <>
    <HelmetProvider>
      <Banner />
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<App />} />
          <Route exact path='country-selector' element={<Country />} />

          {/* People */}
          <Route path='view/person/:personID' element={<PeopleDetails />} />
          <Route
            path='popular/people'
            element={
              <TrendingList
                type='person/popular'
                path='profile'
                name='Popular people this week'
                link='view/person'
              />
            }
          />
          <Route
            path='trending/people'
            element={
              <TrendingList
                type='trending/person/week'
                path='profile'
                name='Trending people this week'
                link='view/person'
              />
            }
          />

          {/* Search */}
          {/* <Route exact path='search' element={<Search />} />
            <Route exact path='search/:searchID' element={<Search />} /> */}

          {/* Movies */}
          <Route path='view/movie/:movieID' element={<MovieDetails />} />

          <Route
            exact
            path='trending/movies'
            element={
              <TrendingList
                type='trending/movie/week'
                name='Trending movies this week'
                link='view/movie'
              />
            }
          />
          <Route
            exact
            path='popular/movies'
            element={
              <TrendingList
                type='movie/popular'
                name='Popular movies this week'
                link='view/movie'
              />
            }
          />
          <Route
            exact
            path='top-rated/movies'
            element={
              <TrendingList
                type='movie/top_rated'
                name='Top rated movies'
                link='view/movie'
              />
            }
          />
          <Route
            exact
            path='upcoming/movies'
            element={
              <TrendingList
                type='movie/upcoming'
                name='Upcoming movies'
                link='view/movie'
              />
            }
          />
          <Route
            exact
            path='now-playing/movies'
            element={
              <TrendingList
                type='movie/now_playing'
                name='Movies playing in theatres'
                link='view/movie'
              />
            }
          />
          <Route
            exact
            path='discover/movies'
            element={
              <TrendingList
                type='discover/movie'
                name='Discover New Movies'
                link='view/movie'
              />
            }
          />

          {/* Movies with Genres */}
          <Route
            exact
            path='discover/movies/genre/action'
            element={
              <TrendingList
                type='discover/movie'
                name='Action Movies'
                link='view/movie'
                genre='28'
              />
            }
          />
          <Route
            exact
            path='discover/movies/genre/adventure'
            element={
              <TrendingList
                type='discover/movie'
                name='Adventure Movies'
                link='view/movie'
                genre='12'
              />
            }
          />
          <Route
            exact
            path='discover/movies/genre/comedy'
            element={
              <TrendingList
                type='discover/movie'
                name='Comedy Movies'
                link='view/movie'
                genre='35'
              />
            }
          />
          <Route
            exact
            path='discover/movies/genre/crime'
            element={
              <TrendingList
                type='discover/movie'
                name='Crime Movies'
                link='view/movie'
                genre='80'
              />
            }
          />
          <Route
            exact
            path='discover/movies/genre/documentaries'
            element={
              <TrendingList
                type='discover/movie'
                name='Documentary Movies'
                link='view/movie'
                genre='99'
              />
            }
          />
          <Route
            exact
            path='discover/movies/genre/drama'
            element={
              <TrendingList
                type='discover/movie'
                name='Drama Movies'
                link='view/movie'
                genre='18'
              />
            }
          />
          <Route
            exact
            path='discover/movies/genre/horror'
            element={
              <TrendingList
                type='discover/movie'
                name='Horror Movies'
                link='view/movie'
                genre='27'
              />
            }
          />
          <Route
            exact
            path='discover/movies/genre/mystery'
            element={
              <TrendingList
                type='discover/movie'
                name='Mystery Movies'
                link='view/movie'
                genre='9648'
              />
            }
          />
          <Route
            exact
            path='discover/movies/genre/romance'
            element={
              <TrendingList
                type='discover/movie'
                name='Romantic Movies'
                link='view/movie'
                genre='10749'
              />
            }
          />
          <Route
            exact
            path='discover/movies/genre/science-fiction'
            element={
              <TrendingList
                type='discover/movie'
                name='Sci-Fi Movies'
                link='view/movie'
                genre='878'
              />
            }
          />
          <Route
            exact
            path='discover/movies/genre/thriller'
            element={
              <TrendingList
                type='discover/movie'
                name='Thriller Movies'
                link='view/movie'
                genre='53'
              />
            }
          />

          {/* TV Shows */}
          <Route path='view/tv/:tvID' element={<TVShowDetails />} />

          {/* <Route
              path='view/tv/:tvID/season/:seasonID'
              element={<Seasons />}
            /> */}

          <Route
            path='view/tv/:tvID/season/:seasonID/episode/:episodeID'
            element={<Episodes />}
          />

          <Route
            exact
            path='trending/tv'
            element={
              <TrendingList
                type='trending/tv/week'
                name='Trending TV Shows'
                link='view/tv'
              />
            }
          />
          <Route
            exact
            path='popular/tv'
            element={
              <TrendingList
                type='tv/popular'
                name='Popular TV Shows'
                link='view/tv'
              />
            }
          />
          <Route
            exact
            path='airing-today/tv'
            element={
              <TrendingList
                type='tv/airing_today'
                name='TV Shows airing today'
                link='view/tv'
              />
            }
          />
          <Route
            exact
            path='top-rated/tv'
            element={
              <TrendingList
                type='tv/top_rated'
                name='Top rated TV Shows'
                link='view/tv'
              />
            }
          />
          <Route
            exact
            path='on_the_air/tv'
            element={
              <TrendingList
                type='tv/on_the_air'
                name='TV Shows On The Air '
                link='view/tv'
              />
            }
          />

          {/* TV Shows with Genres */}
          <Route
            exact
            path='discover/tv/genre/action-and-adventure'
            element={
              <TrendingList
                type='discover/tv'
                name='Action & Adventure TV Shows'
                link='view/tv'
                genre='10759'
                network=''
              />
            }
          />
          <Route
            exact
            path='discover/tv/genre/crime'
            element={
              <TrendingList
                type='discover/tv'
                name='Crime TV Shows'
                link='view/tv'
                genre='80'
                network=''
              />
            }
          />
          <Route
            exact
            path='discover/tv/genre/comedy'
            element={
              <TrendingList
                type='discover/tv'
                name='Comedy TV Shows'
                link='view/tv'
                genre='35'
                network=''
              />
            }
          />
          <Route
            exact
            path='discover/tv/genre/drama'
            element={
              <TrendingList
                type='discover/tv'
                name='Drama TV Shows'
                link='view/tv'
                genre='18'
                network=''
              />
            }
          />
          <Route
            exact
            path='discover/tv/genre/family'
            element={
              <TrendingList
                type='discover/tv'
                name='Family TV Shows'
                link='view/tv'
                genre='10751'
                network=''
              />
            }
          />
          <Route
            exact
            path='discover/tv/genre/kids'
            element={
              <TrendingList
                type='discover/tv'
                name='Kids TV Shows'
                link='view/tv'
                genre='10762'
              />
            }
            network=''
          />
          <Route
            exact
            path='discover/tv/genre/reality'
            element={
              <TrendingList
                type='discover/tv'
                name='Reality TV Shows'
                link='view/tv'
                genre='10764'
                network=''
              />
            }
          />
          <Route
            exact
            path='discover/tv/genre/mystery'
            element={
              <TrendingList
                type='discover/tv'
                name='Mystery TV Shows'
                link='view/tv'
                genre='9648'
                network=''
              />
            }
          />
          <Route
            exact
            path='discover/tv/genre/romance'
            element={
              <TrendingList
                type='discover/tv'
                name='Romantic TV Shows'
                link='view/tv'
                genre='10749'
                network=''
              />
            }
          />
          <Route
            exact
            path='discover/tv/genre/documentaries'
            element={
              <TrendingList
                type='discover/tv'
                name='Documentary TV Shows'
                link='view/tv'
                genre='99'
                network=''
              />
            }
          />
          <Route
            exact
            path='discover/tv/genre/science-fiction-and-fantasy'
            element={
              <TrendingList
                type='discover/tv'
                name='Sci-Fi & Fantasy TV Shows'
                link='view/tv'
                genre='10765'
                network=''
              />
            }
          />

          {/* TV Shows with Networks */}
          <Route
            exact
            path='discover/tv/network/amc'
            element={
              <TrendingList
                type='discover/tv'
                name='AMC+ Originals'
                link='view/tv'
                network='174'
                genre=''
              />
            }
          />
          <Route
            exact
            path='discover/tv/network/apple'
            element={
              <TrendingList
                type='discover/tv'
                name='Apple TV+ Originals'
                link='view/tv'
                network='2552'
                genre=''
              />
            }
          />
          <Route
            exact
            path='discover/tv/network/disney'
            element={
              <TrendingList
                type='discover/tv'
                name='Disney+ Originals'
                link='view/tv'
                network='2739'
                genre=''
              />
            }
          />
          <Route
            exact
            path='discover/tv/network/fx'
            element={
              <TrendingList
                type='discover/tv'
                name='FX Originals'
                link='view/tv'
                network='88'
                genre=''
              />
            }
          />
          <Route
            exact
            path='discover/tv/network/hbo'
            element={
              <TrendingList
                type='discover/tv'
                name='HBO Originals'
                link='view/tv'
                network='49'
                genre=''
              />
            }
          />
          <Route
            exact
            path='discover/tv/network/hulu'
            element={
              <TrendingList
                type='discover/tv'
                name='Hulu Originals'
                link='view/tv'
                network='453'
                genre=''
              />
            }
          />
          <Route
            exact
            path='discover/tv/network/netflix'
            element={
              <TrendingList
                type='discover/tv'
                name='Netflix Originals'
                link='view/tv'
                network='213'
                genre=''
              />
            }
          />
          <Route
            exact
            path='discover/tv/network/paramount'
            element={
              <TrendingList
                type='discover/tv'
                name='Paramount+ Originals'
                link='view/tv'
                network='4330'
                genre=''
              />
            }
          />
          <Route
            exact
            path='discover/tv/network/showtime'
            element={
              <TrendingList
                type='discover/tv'
                name='Showtime Originals'
                link='view/tv'
                network='67'
                genre=''
              />
            }
          />

          <Route
            path='view/collection/:collectionID'
            element={<Collection />}
          />

          {/* Safe Search and Other */}
          <Route path='safesearch' element={<SafeSearch />} />
          <Route path='*' element={<NotFound />} />

          {/* Account Management */}
          <Route path='account/login' element={<Login />} />
          <Route path='account/favorites' element={<Favorites />} />
          <Route path='account/register' element={<Register />} />
          <Route path='account' element={<Account />} />
          <Route path='account/password-reset' element={<PasswordReset />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </HelmetProvider>
    <button onClick={topFunction} id='top-button' title='Go to top'>
      <span className='material-symbols-outlined'> keyboard_arrow_up </span>
    </button>
  </>
);

export function NotFound() {
  return (
    <div className='main'>
      <CustomNavbar />
      <main className='page-error'>
        <h1>Error 404</h1>
        <p>
          The requested URL <strong>{window.location.pathname}</strong> was not
          found on this server.
        </p>
      </main>
    </div>
  );
}
