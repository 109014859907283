// React
import { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

// Firebase
import {
  signInWithPopup,
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  sendEmailVerification,
  TwitterAuthProvider,
  OAuthProvider,
  sendSignInLinkToEmail,
  isSignInWithEmailLink,
  signInWithEmailLink,
  GithubAuthProvider,
} from 'firebase/auth';
import { auth } from '../../firebase';

// Custom Functions
import { CustomNavbar } from '../../Components';
import {
  authErrorCodes,
  errorNotification,
  successNotification,
} from '../../Helpers/utils';
import { Turnstile } from '@marsidev/react-turnstile';

// MailChecker
import MailChecker from 'mailchecker';

// Assets
import Google from '../../Assets/Images/google.svg';
import Twitter from '../../Assets/Images/twitter.svg';
import Microsoft from '../../Assets/Images/microsoft.svg';
import GitHub from '../../Assets/Images/github.svg';

export default function Login() {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [token, setToken] = useState();
  const reference = useRef();

  const [manual, setManual] = useState(true);
  const [success, setSuccess] = useState(false);

  const googleProvider = new GoogleAuthProvider();
  const twitterProvider = new TwitterAuthProvider();
  const microsoftProvider = new OAuthProvider('microsoft.com');
  const githubProvider = new GithubAuthProvider();

  const socialSignIn = (props) => {
    signInWithPopup(auth, props)
      .then(() => {
        navigate('/account');
      })
      .catch((error) => {
        errorNotification(authErrorCodes[error.code]);
        reference.current?.reset();
      });
  };

  const magicLink = async () => {
    const response = await fetch(`https://turnstile.skuflic.net?rt=${token}`)
      .then((response) => response.json())
      .then((data) => {
        if (!data.success) {
          reference.current?.reset();
        }
        return data.success;
      });
    const actionCodeSettings = {
      url: 'https://reel.skuflic.com/account',
      handleCodeInApp: true,
    };
    if (MailChecker.isValid(email) && response === true) {
      try {
        sendSignInLinkToEmail(auth, email, actionCodeSettings)
          .then(() => {
            setSuccess(true);
            localStorage.setItem('emailForSignIn', email);
          })
          .catch((error) => {
            errorNotification(
              `${authErrorCodes[error.code]} Please try again later.`
            );
            reference.current?.reset();
          });
      } catch (error) {
        reference.current?.reset();
        errorNotification(authErrorCodes[error.code]);
      }
    }
  };

  const onLogin = async (e) => {
    e.preventDefault();
    const response = await fetch(`https://turnstile.skuflic.net?rt=${token}`)
      .then((response) => response.json())
      .then((data) => {
        if (!data.success) {
          reference.current?.reset();
        }
        return data.success;
      });
    if (MailChecker.isValid(email) && response === true) {
      try {
        let userCredential = await signInWithEmailAndPassword(
          auth,
          email,
          password
        );
        if (userCredential.user.emailVerified) {
          navigate('/account');
        } else {
          sendEmailVerification(auth.currentUser);
          successNotification(
            `An email verification link has been sent to ${email}. Please verify your email address to sign in.`
          );
        }
      } catch (error) {
        reference.current?.reset();
        errorNotification(authErrorCodes[error.code]);
      }
    }
  };

  useEffect(() => {
    if (auth.currentUser) {
      navigate('/account');
    }

    if (isSignInWithEmailLink(auth, window.location.href)) {
      let email = window.localStorage.getItem('emailForSignIn');
      if (!email) {
        // User opened the link on a different device. To prevent session fixation
        // attacks, ask the user to provide the associated email again. For example:
        email = window.prompt('Please provide your email for confirmation');
      }
      // The client SDK will parse the code from the link for you.
      signInWithEmailLink(auth, email, window.location.href)
        .then((result) => {
          // Clear email from storage.
          window.localStorage.removeItem('emailForSignIn');
          navigate('/account');
        })
        .catch((error) => {
          errorNotification(`${authErrorCodes[error.code]}`);
        });
    }
  }, [auth.currentUser]);

  document.title = `Sign In - Reel`;
  if (success === false) {
    return (
      <>
        <div className='main'>
          <CustomNavbar />
          <div className='account'>
            <h1>Sign In</h1>
            <p>
              Don't have an account? <a href='/account/register'>Sign Up</a>
            </p>
            <br />
            <p>
              Login via social is currently disabled as we are updating the
              entire Reel experience. <br />
              Stay tuned for exciting improvements!
            </p>
            <div className='login-form'>
              <div className='input-field'>
                <div className='help-text'>EMAIL ADDRESS</div>
                <input
                  id='email-address'
                  name='email'
                  type='email'
                  required
                  className='field'
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>

              {manual && (
                <p className='passwordless'>
                  <span className='material-symbols-outlined'> key </span>We'll
                  email you a magic link for a passwordless sign in, or you can{' '}
                  <span onClick={() => setManual(false)} className='manual'>
                    sign in manually instead.
                  </span>
                </p>
              )}

              {!manual && (
                <div className='input-field'>
                  <div className='help-text'>PASSWORD</div>
                  <input
                    id='password'
                    name='password'
                    type='password'
                    required
                    className='field'
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
              )}

              <button
                onClick={!manual ? onLogin : magicLink}
                className='button'>
                {!manual ? 'Sign In' : 'Email Me A Link'}
              </button>

              {!manual && (
                <p className='password-reset'>
                  <a href='/account/password-reset'>Forgot password?</a>
                </p>
              )}
            </div>
            {
              <Turnstile
                siteKey='0x4AAAAAAAM_D35F4o3pwJXW'
                className='turnstile'
                id='login'
                options={{
                  action: 'login',
                  theme: 'dark',
                  size: 'normal',
                  language: 'en',
                  refreshExpired: 'auto',
                  appearance: 'interaction-only',
                }}
                onSuccess={setToken}
                ref={reference}
              />
            }
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className='main'>
          <CustomNavbar />
          <div className='account'>
            <h1>Check Your Email ✨</h1>
            <p>
              If you have a <strong>Reel</strong> account associated with{' '}
              <strong>{email}</strong>, we will email you a log in link.
            </p>
            <p>
              {' '}
              If you haven't received the email in 30 minutes, please double
              check your junk or spam folder. You will see an email from
              noreply@email.skuflic.com.
            </p>
            <a
              href='https://go.skuflic.com/support'
              target='_blank'
              rel='noreferrer'
              className='issues'>
              Need help with logging in or signing up?
            </a>
          </div>
        </div>
      </>
    );
  }
}
