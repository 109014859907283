import { toast } from 'react-toastify';
import pluralize from 'pluralize';

export function getCookie(name) {
  const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
  return match ? match[2] : null;
}

const toastOptions = {
  position: 'top-right',
  autoClose: 4000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: 'dark',
};

export function errorNotification(message) {
  toast.error(message, toastOptions);
}

export function successNotification(message) {
  toast.success(message, toastOptions);
}

const CACHE_DURATION = 3600000; // 1 hour in milliseconds
const cache = new Map();

export async function fetchWithCache(url, cacheKey) {
  const cachedData = cache.get(cacheKey);
  const now = Date.now();

  if (cachedData && now - cachedData.timestamp < CACHE_DURATION) {
    return cachedData.data;
  }

  const response = await fetch(url, {
    cache: 'force-cache',
    headers: {
      'Cache-Control': 'max-age=3600', // Cache for 1 hour
    },
  });

  if (!response.ok) {
    throw new Error(`Failed to fetch from ${url}`);
  }

  const data = await response.json();
  cache.set(cacheKey, { data, timestamp: now });
  return data;
}

export function slider(id, direction) {
  const container = document.getElementById(id);
  const children = Array.from(container.children);

  const currentPosition = container.scrollLeft;
  let targetPosition = currentPosition;

  // Find the snap positions
  const snapPositions = children.map((child) => child.offsetLeft);

  // Determine the next position based on direction
  if (direction === 'left') {
    for (let i = snapPositions.length - 1; i >= 0; i--) {
      if (snapPositions[i] < currentPosition) {
        targetPosition = snapPositions[i];
        break;
      }
    }
  } else {
    for (let i = 0; i < snapPositions.length; i++) {
      if (snapPositions[i] > currentPosition) {
        targetPosition = snapPositions[i];
        break;
      }
    }
  }

  container.scrollTo({
    left: targetPosition,
    behavior: 'smooth',
  });
}

export function ageCalculator(dateString) {
  const birthday = +new Date(dateString);
  const ageInMilliseconds = Date.now() - birthday;
  const ageInYears = ageInMilliseconds / (365.25 * 24 * 60 * 60 * 1000);
  return Math.floor(ageInYears);
}

export function deathCalculator(dob, dod) {
  const diffMilliseconds = Math.abs(new Date(dod) - new Date(dob));
  const diffYears = diffMilliseconds / (365.25 * 24 * 60 * 60 * 1000);
  return Math.floor(diffYears);
}

export function calculateNew(passed) {
  const date1 = new Date(passed);
  const date2 = Date.now();
  const differenceInTime = date2 - date1.getTime();
  return differenceInTime / 86400000;
}

export const dateFormatting_YMD = {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
};
export const dateFormatting_Y = { year: 'numeric' };
export const dateFormatting_YM = { year: 'numeric', month: 'long' };

window.scroll({
  top: 0,
  left: 0,
  behavior: 'smooth',
});

export function time_convert(num) {
  const hours = Math.floor(num / 60);
  const minutes = num % 60;
  return `${
    hours === 0 ? `` : `${hours} ${pluralize('hour', hours)}`
  } ${minutes} ${pluralize('minute', minutes)}`;
}

const lookup = [
  { value: 1, symbol: '' },
  { value: 1e3, symbol: 'k' },
  { value: 1e6, symbol: 'M' },
  { value: 1e9, symbol: 'G' },
  { value: 1e12, symbol: 'T' },
  { value: 1e15, symbol: 'P' },
  { value: 1e18, symbol: 'E' },
];
const reversedLookup = lookup.slice().reverse();

export function number_formatter(num, digits) {
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = reversedLookup.find(function (item) {
    return num >= item.value;
  });
  return item
    ? (num / item.value).toFixed(digits).replace(rx, '$1') + item.symbol
    : '0';
}

export const faqs = [
  {
    question: 'What is Reel used for?',
    answer: `Reel is a platform designed to help users discover movies and TV shows effortlessly. It provides comprehensive details, including reviews, ratings, cast and crew information, release dates, trailers, and where to watch each title. Whether you're looking for recommendations, trending content, or hidden gems, Reel helps you make informed viewing decisions.`,
  },
  // {
  //   question: 'Where can I watch movies and TV shows?',
  //   answer: `Stream iconic series, hit movies, fresh originals, and family favourites on Max. Max offers a choice of different plans, plus a Sports Add-On which may be added to any base plan. No commitments, cancel whenever you want.`,
  //   url: 'https://max.prf.hn/click/camref:1100l3MAUe/creativeref:1100l135068',
  //   cta: 'Learn more',
  // },
  {
    question: 'How can I search for a specific movie or TV show?',
    answer: `You can use the search bar at the top of Reel to find a specific title. Simply enter the movie or show name, director, actor, genre, or any relevant keyword, and Reel will provide accurate search results based on your query.`,
  },
  {
    question: 'Can I create a watchlist?',
    answer: `Yes! Reel allows you to create a personalized watchlist (registration required) to keep track of movies and TV shows you want to watch. Just click the "Add to Watchlist" button on a title's page, and you can easily access your saved content anytime.`,
  },
  {
    question: 'Can I rate movies and TV shows?',
    answer: `Absolutely! You can rate movies and TV shows on Reel by selecting from a range of emojis on a title's page. Your ratings help improve recommendations and assist other users in discovering great content.`,
  },
  {
    question: 'Can I share titles with my friends?',
    answer: `Yes! Reel makes it easy to share your favorite movies and TV shows with friends. You can share titles directly through social media platforms, email, or messaging apps with just a few clicks.`,
  },
  {
    question: 'Does Reel provide information about upcoming releases?',
    answer: `Yes! Reel keeps you updated on upcoming movie and TV show releases. You can find details about new titles, release dates, official trailers, and even early reviews, ensuring you never miss out on the latest entertainment.`,
  },
];

export const countries = [
  { value: 'AF', label: 'Afghanistan' },
  { value: 'AX', label: 'Åland Islands' },
  { value: 'AL', label: 'Albania' },
  { value: 'DZ', label: 'Algeria' },
  { value: 'AS', label: 'American Samoa' },
  { value: 'AD', label: 'Andorra' },
  { value: 'AO', label: 'Angola' },
  { value: 'AI', label: 'Anguilla' },
  { value: 'AQ', label: 'Antarctica' },
  { value: 'AG', label: 'Antigua and Barbuda' },
  { value: 'AR', label: 'Argentina' },
  { value: 'AM', label: 'Armenia' },
  { value: 'AW', label: 'Aruba' },
  { value: 'AU', label: 'Australia' },
  { value: 'AT', label: 'Austria' },
  { value: 'AZ', label: 'Azerbaijan' },
  { value: 'BS', label: 'Bahamas' },
  { value: 'BH', label: 'Bahrain' },
  { value: 'BD', label: 'Bangladesh' },
  { value: 'BB', label: 'Barbados' },
  { value: 'BY', label: 'Belarus' },
  { value: 'BE', label: 'Belgium' },
  { value: 'BZ', label: 'Belize' },
  { value: 'BJ', label: 'Benin' },
  { value: 'BM', label: 'Bermuda' },
  { value: 'BT', label: 'Bhutan' },
  { value: 'BO', label: 'Bolivia, Plurinational State of' },
  { value: 'BQ', label: 'Bonaire, Sint Eustatius and Saba' },
  { value: 'BA', label: 'Bosnia and Herzegovina' },
  { value: 'BW', label: 'Botswana' },
  { value: 'BV', label: 'Bouvet Island' },
  { value: 'BR', label: 'Brazil' },
  { value: 'IO', label: 'British Indian Ocean Territory' },
  { value: 'BN', label: 'Brunei Darussalam' },
  { value: 'BG', label: 'Bulgaria' },
  { value: 'BF', label: 'Burkina Faso' },
  { value: 'BI', label: 'Burundi' },
  { value: 'KH', label: 'Cambodia' },
  { value: 'CM', label: 'Cameroon' },
  { value: 'CA', label: 'Canada' },
  { value: 'CV', label: 'Cape Verde' },
  { value: 'KY', label: 'Cayman Islands' },
  { value: 'CF', label: 'Central African Republic' },
  { value: 'TD', label: 'Chad' },
  { value: 'CL', label: 'Chile' },
  { value: 'CN', label: 'China' },
  { value: 'CX', label: 'Christmas Island' },
  { value: 'CC', label: 'Cocos (Keeling) Islands' },
  { value: 'CO', label: 'Colombia' },
  { value: 'KM', label: 'Comoros' },
  { value: 'CG', label: 'Congo' },
  { value: 'CD', label: 'Congo, the Democratic Republic of the' },
  { value: 'CK', label: 'Cook Islands' },
  { value: 'CR', label: 'Costa Rica' },
  { value: 'CI', label: "Côte d'Ivoire" },
  { value: 'HR', label: 'Croatia' },
  { value: 'CU', label: 'Cuba' },
  { value: 'CW', label: 'Curaçao' },
  { value: 'CY', label: 'Cyprus' },
  { value: 'CZ', label: 'Czech Republic' },
  { value: 'DK', label: 'Denmark' },
  { value: 'DJ', label: 'Djibouti' },
  { value: 'DM', label: 'Dominica' },
  { value: 'DO', label: 'Dominican Republic' },
  { value: 'EC', label: 'Ecuador' },
  { value: 'EG', label: 'Egypt' },
  { value: 'SV', label: 'El Salvador' },
  { value: 'GQ', label: 'Equatorial Guinea' },
  { value: 'ER', label: 'Eritrea' },
  { value: 'EE', label: 'Estonia' },
  { value: 'ET', label: 'Ethiopia' },
  { value: 'FK', label: 'Falkland Islands (Malvinas)' },
  { value: 'FO', label: 'Faroe Islands' },
  { value: 'FJ', label: 'Fiji' },
  { value: 'FI', label: 'Finland' },
  { value: 'FR', label: 'France' },
  { value: 'GF', label: 'French Guiana' },
  { value: 'PF', label: 'French Polynesia' },
  { value: 'TF', label: 'French Southern Territories' },
  { value: 'GA', label: 'Gabon' },
  { value: 'GM', label: 'Gambia' },
  { value: 'GE', label: 'Georgia' },
  { value: 'DE', label: 'Germany' },
  { value: 'GH', label: 'Ghana' },
  { value: 'GI', label: 'Gibraltar' },
  { value: 'GR', label: 'Greece' },
  { value: 'GL', label: 'Greenland' },
  { value: 'GD', label: 'Grenada' },
  { value: 'GP', label: 'Guadeloupe' },
  { value: 'GU', label: 'Guam' },
  { value: 'GT', label: 'Guatemala' },
  { value: 'GG', label: 'Guernsey' },
  { value: 'GN', label: 'Guinea' },
  { value: 'GW', label: 'Guinea-Bissau' },
  { value: 'GY', label: 'Guyana' },
  { value: 'HT', label: 'Haiti' },
  { value: 'HM', label: 'Heard Island and McDonald Mcdonald Islands' },
  { value: 'VA', label: 'Holy See (Vatican City State)' },
  { value: 'HN', label: 'Honduras' },
  { value: 'HK', label: 'Hong Kong' },
  { value: 'HU', label: 'Hungary' },
  { value: 'IS', label: 'Iceland' },
  { value: 'IN', label: 'India' },
  { value: 'ID', label: 'Indonesia' },
  { value: 'IR', label: 'Iran, Islamic Republic of' },
  { value: 'IQ', label: 'Iraq' },
  { value: 'IE', label: 'Ireland' },
  { value: 'IM', label: 'Isle of Man' },
  { value: 'IL', label: 'Israel' },
  { value: 'IT', label: 'Italy' },
  { value: 'JM', label: 'Jamaica' },
  { value: 'JP', label: 'Japan' },
  { value: 'JE', label: 'Jersey' },
  { value: 'JO', label: 'Jordan' },
  { value: 'KZ', label: 'Kazakhstan' },
  { value: 'KE', label: 'Kenya' },
  { value: 'KI', label: 'Kiribati' },
  { value: 'KP', label: "Korea, Democratic People's Republic of" },
  { value: 'KR', label: 'Korea, Republic of' },
  { value: 'XK', label: 'Kosovo' },
  { value: 'KW', label: 'Kuwait' },
  { value: 'KG', label: 'Kyrgyzstan' },
  { value: 'LA', label: "Lao People's Democratic Republic" },
  { value: 'LV', label: 'Latvia' },
  { value: 'LB', label: 'Lebanon' },
  { value: 'LS', label: 'Lesotho' },
  { value: 'LR', label: 'Liberia' },
  { value: 'LY', label: 'Libya' },
  { value: 'LI', label: 'Liechtenstein' },
  { value: 'LT', label: 'Lithuania' },
  { value: 'LU', label: 'Luxembourg' },
  { value: 'MO', label: 'Macao' },
  { value: 'MK', label: 'Macedonia, the Former Yugoslav Republic of' },
  { value: 'MG', label: 'Madagascar' },
  { value: 'MW', label: 'Malawi' },
  { value: 'MY', label: 'Malaysia' },
  { value: 'MV', label: 'Maldives' },
  { value: 'ML', label: 'Mali' },
  { value: 'MT', label: 'Malta' },
  { value: 'MH', label: 'Marshall Islands' },
  { value: 'MQ', label: 'Martinique' },
  { value: 'MR', label: 'Mauritania' },
  { value: 'MU', label: 'Mauritius' },
  { value: 'YT', label: 'Mayotte' },
  { value: 'MX', label: 'Mexico' },
  { value: 'FM', label: 'Micronesia, Federated States of' },
  { value: 'MD', label: 'Moldova, Republic of' },
  { value: 'MC', label: 'Monaco' },
  { value: 'MN', label: 'Mongolia' },
  { value: 'ME', label: 'Montenegro' },
  { value: 'MS', label: 'Montserrat' },
  { value: 'MA', label: 'Morocco' },
  { value: 'MZ', label: 'Mozambique' },
  { value: 'MM', label: 'Myanmar' },
  { value: 'NA', label: 'Namibia' },
  { value: 'NR', label: 'Nauru' },
  { value: 'NP', label: 'Nepal' },
  { value: 'NL', label: 'Netherlands' },
  { value: 'NC', label: 'New Caledonia' },
  { value: 'NZ', label: 'New Zealand' },
  { value: 'NI', label: 'Nicaragua' },
  { value: 'NE', label: 'Niger' },
  { value: 'NG', label: 'Nigeria' },
  { value: 'NU', label: 'Niue' },
  { value: 'NF', label: 'Norfolk Island' },
  { value: 'MP', label: 'Northern Mariana Islands' },
  { value: 'NO', label: 'Norway' },
  { value: 'OM', label: 'Oman' },
  { value: 'PK', label: 'Pakistan' },
  { value: 'PW', label: 'Palau' },
  { value: 'PS', label: 'Palestine, State of' },
  { value: 'PA', label: 'Panama' },
  { value: 'PG', label: 'Papua New Guinea' },
  { value: 'PY', label: 'Paraguay' },
  { value: 'PE', label: 'Peru' },
  { value: 'PH', label: 'Philippines' },
  { value: 'PN', label: 'Pitcairn' },
  { value: 'PL', label: 'Poland' },
  { value: 'PT', label: 'Portugal' },
  { value: 'PR', label: 'Puerto Rico' },
  { value: 'QA', label: 'Qatar' },
  { value: 'RE', label: 'Réunion' },
  { value: 'RO', label: 'Romania' },
  { value: 'RU', label: 'Russian Federation' },
  { value: 'RW', label: 'Rwanda' },
  { value: 'BL', label: 'Saint Barthélemy' },
  { value: 'SH', label: 'Saint Helena, Ascension and Tristan da Cunha' },
  { value: 'KN', label: 'Saint Kitts and Nevis' },
  { value: 'LC', label: 'Saint Lucia' },
  { value: 'MF', label: 'Saint Martin (French part)' },
  { value: 'PM', label: 'Saint Pierre and Miquelon' },
  { value: 'VC', label: 'Saint Vincent and the Grenadines' },
  { value: 'WS', label: 'Samoa' },
  { value: 'SM', label: 'San Marino' },
  { value: 'ST', label: 'Sao Tome and Principe' },
  { value: 'SA', label: 'Saudi Arabia' },
  { value: 'SN', label: 'Senegal' },
  { value: 'RS', label: 'Serbia' },
  { value: 'SC', label: 'Seychelles' },
  { value: 'SL', label: 'Sierra Leone' },
  { value: 'SG', label: 'Singapore' },
  { value: 'SX', label: 'Sint Maarten (Dutch part)' },
  { value: 'SK', label: 'Slovakia' },
  { value: 'SI', label: 'Slovenia' },
  { value: 'SB', label: 'Solomon Islands' },
  { value: 'SO', label: 'Somalia' },
  { value: 'ZA', label: 'South Africa' },
  { value: 'GS', label: 'South Georgia and the South Sandwich Islands' },
  { value: 'SS', label: 'South Sudan' },
  { value: 'ES', label: 'Spain' },
  { value: 'LK', label: 'Sri Lanka' },
  { value: 'SD', label: 'Sudan' },
  { value: 'SR', label: 'Suriname' },
  { value: 'SJ', label: 'Svalbard and Jan Mayen' },
  { value: 'SZ', label: 'Swaziland' },
  { value: 'SE', label: 'Sweden' },
  { value: 'CH', label: 'Switzerland' },
  { value: 'SY', label: 'Syrian Arab Republic' },
  { value: 'TW', label: 'Taiwan, Province of China' },
  { value: 'TJ', label: 'Tajikistan' },
  { value: 'TZ', label: 'Tanzania, United Republic of' },
  { value: 'TH', label: 'Thailand' },
  { value: 'TL', label: 'Timor-Leste' },
  { value: 'TG', label: 'Togo' },
  { value: 'TK', label: 'Tokelau' },
  { value: 'TO', label: 'Tonga' },
  { value: 'TT', label: 'Trinidad and Tobago' },
  { value: 'TN', label: 'Tunisia' },
  { value: 'TR', label: 'Turkey' },
  { value: 'TM', label: 'Turkmenistan' },
  { value: 'TC', label: 'Turks and Caicos Islands' },
  { value: 'TV', label: 'Tuvalu' },
  { value: 'UG', label: 'Uganda' },
  { value: 'UA', label: 'Ukraine' },
  { value: 'AE', label: 'United Arab Emirates' },
  { value: 'GB', label: 'United Kingdom' },
  { value: 'US', label: 'United States' },
  { value: 'UM', label: 'United States Minor Outlying Islands' },
  { value: 'UY', label: 'Uruguay' },
  { value: 'UZ', label: 'Uzbekistan' },
  { value: 'VU', label: 'Vanuatu' },
  { value: 'VE', label: 'Venezuela, Bolivarian Republic of' },
  { value: 'VN', label: 'Viet Nam' },
  { value: 'VG', label: 'Virgin Islands, British' },
  { value: 'VI', label: 'Virgin Islands, U.S.' },
  { value: 'WF', label: 'Wallis and Futuna' },
  { value: 'EH', label: 'Western Sahara' },
  { value: 'YE', label: 'Yemen' },
  { value: 'ZM', label: 'Zambia' },
  { value: 'ZW', label: 'Zimbabwe' },
];

export const listOfGenres = {
  28: 'Action',
  12: 'Adventure',
  16: 'Animation',
  35: 'Comedy',
  80: 'Crime',
  99: 'Documentary',
  18: 'Drama',
  10751: 'Family',
  14: 'Fantasy',
  36: 'History',
  27: 'Horror',
  10402: 'Music',
  9648: 'Romance',
  10749: 'Romance',
  878: 'Science Fiction',
  10770: 'TV Movie',
  53: 'Thriller',
  10752: 'War',
  37: 'Western',
  10759: 'Action & Adventure',
  10762: 'Kids',
  10763: 'News',
  10764: 'Reality',
  10765: 'Sci-Fi & Fantasy',
  10766: 'Soap',
  10767: 'Talk',
  10768: 'War & Politics',
};

export const listOfCountries = {
  AF: 'Afghanistan',
  AX: 'Åland Islands',
  AL: 'Albania',
  DZ: 'Algeria',
  AS: 'American Samoa',
  AD: 'Andorra',
  AO: 'Angola',
  AI: 'Anguilla',
  AQ: 'Antarctica',
  AG: 'Antigua and Barbuda',
  AR: 'Argentina',
  AM: 'Armenia',
  AW: 'Aruba',
  AU: 'Australia',
  AT: 'Austria',
  AZ: 'Azerbaijan',
  BS: 'Bahamas',
  BH: 'Bahrain',
  BD: 'Bangladesh',
  BB: 'Barbados',
  BY: 'Belarus',
  BE: 'Belgium',
  BZ: 'Belize',
  BJ: 'Benin',
  BM: 'Bermuda',
  BT: 'Bhutan',
  BO: 'Bolivia',
  BQ: 'Bonaire, Sint Eustatius and Saba',
  BA: 'Bosnia and Herzegovina',
  BW: 'Botswana',
  BV: 'Bouvet Island',
  BR: 'Brazil',
  IO: 'British Indian Ocean Territory`',
  BN: 'Brunei Darussalam',
  BG: 'Bulgaria',
  BF: 'Burkina Faso',
  BI: 'Burundi',
  CV: 'Cabo Verde',
  KH: 'Cambodia',
  CM: 'Cameroon',
  CA: 'Canada',
  KY: 'Cayman Islands`',
  CF: 'Central African Republic',
  TD: 'Chad',
  CL: 'Chile',
  CN: 'China',
  CX: 'Christmas Island',
  CC: 'Cocos (Keeling) Islands',
  CO: 'Colombia',
  KM: 'Comoros`',
  CD: 'Congo',
  CG: 'Congo',
  CK: 'Cook Islands',
  CR: 'Costa Rica',
  HR: 'Croatia',
  CU: 'Cuba',
  CW: 'Curaçao',
  CY: 'Cyprus',
  CZ: 'Czechia',
  CI: `Côte d'Ivoire`,
  DK: 'Denmark',
  DJ: 'Djibouti',
  DM: 'Dominica',
  DO: 'Dominican Republic',
  EC: 'Ecuador',
  EG: 'Egypt',
  SV: 'El Salvador',
  GQ: 'Equatorial Guinea',
  ER: 'Eritrea',
  EE: 'Estonia',
  SZ: 'Eswatini',
  ET: 'Ethiopia',
  FK: 'Falkland Islands [Malvinas]',
  FO: 'Faroe Islands',
  FJ: 'Fiji',
  FI: 'Finland',
  FR: 'France',
  GF: 'French Guiana',
  PF: 'French Polynesia',
  TF: 'French Southern Territories',
  GA: 'Gabon',
  GM: 'Gambia',
  GE: 'Georgia',
  DE: 'Germany',
  GH: 'Ghana',
  GI: 'Gibraltar',
  GR: 'Greece',
  GL: 'Greenland',
  GD: 'Grenada',
  GP: 'Guadeloupe',
  GU: 'Guam',
  GT: 'Guatemala',
  GG: 'Guernsey',
  GN: 'Guinea',
  GW: 'Guinea-Bissau',
  GY: 'Guyana',
  HT: 'Haiti',
  HM: 'Heard Island and McDonald Islands',
  VA: 'Holy See (Vatican City State)',
  HN: 'Honduras',
  HK: 'Hong Kong',
  HU: 'Hungary',
  IS: 'Iceland',
  IN: 'India',
  ID: 'Indonesia',
  IR: 'Iran',
  IQ: 'Iraq',
  IE: 'Ireland',
  IM: 'Isle of Man',
  IL: 'Israel',
  IT: 'Italy',
  JM: 'Jamaica',
  JP: 'Japan',
  JE: 'Jersey',
  JO: 'Jordan',
  KZ: 'Kazakhstan',
  KE: 'Kenya',
  KI: 'Kiribati',
  KP: `North Korea`,
  KR: 'South Korea',
  XK: 'Kosovo',
  KW: 'Kuwait',
  KG: 'Kyrgyzstan',
  LA: `Lao People's Democratic Republic`,
  LV: 'Latvia',
  LB: 'Lebanon',
  LS: 'Lesotho',
  LR: 'Liberia',
  LY: 'Libya',
  LI: 'Liechtenstein',
  LT: 'Lithuania',
  LU: 'Luxembourg',
  MO: 'Macao',
  MG: 'Madagascar',
  MW: 'Malawi',
  MY: 'Malaysia',
  MV: 'Maldives',
  ML: 'Mali',
  MT: 'Malta',
  MH: 'Marshall Islands',
  MQ: 'Martinique',
  MR: 'Mauritania',
  MU: 'Mauritius',
  YT: 'Mayotte',
  MX: 'Mexico',
  FM: 'Micronesia',
  MD: 'Moldova',
  MC: 'Monaco',
  MN: 'Mongolia',
  ME: 'Montenegro',
  MS: 'Montserrat',
  MA: 'Morocco',
  MZ: 'Mozambique',
  MM: 'Myanmar',
  NA: 'Namibia',
  NR: 'Nauru',
  NP: 'Nepal',
  NL: 'Netherlands',
  NC: 'New Caledonia',
  NZ: 'New Zealand',
  NI: 'Nicaragua',
  NE: 'Niger',
  NG: 'Nigeria',
  NU: 'Niue',
  NF: 'Norfolk Island',
  MP: 'Northern Mariana Islands',
  NO: 'Norway',
  OM: 'Oman',
  PK: 'Pakistan',
  PW: 'Palau',
  PS: 'Palestine',
  PA: 'Panama',
  PG: 'Papua New Guinea',
  PY: 'Paraguay',
  PE: 'Peru',
  PH: 'Philippines',
  PN: 'Pitcairn',
  PL: 'Poland',
  PT: 'Portugal',
  PR: 'Puerto Rico',
  QA: 'Qatar',
  MK: 'Republic of North Macedonia',
  RO: 'Romania',
  RU: 'Russian Federation',
  RW: 'Rwanda',
  RE: 'Réunion',
  BL: 'Saint Barthélemy',
  SH: 'Saint Helena, Ascension and Tristan da Cunha',
  KN: 'Saint Kitts and Nevis',
  LC: 'Saint Lucia',
  MF: 'Saint Martin (French part)',
  PM: 'Saint Pierre and Miquelon',
  VC: 'Saint Vincent and the Grenadines',
  WS: 'Samoa',
  SM: 'San Marino',
  ST: 'Sao Tome and Principe',
  SA: 'Saudi Arabia',
  SN: 'Senegal',
  RS: 'Serbia',
  SC: 'Seychelles',
  SL: 'Sierra Leone',
  SG: 'Singapore',
  SX: 'Sint Maarten (Dutch part)',
  SK: 'Slovakia',
  SI: 'Slovenia',
  SB: 'Solomon Islands',
  SO: 'Somalia',
  ZA: 'South Africa',
  GS: 'South Georgia and the South Sandwich Islands',
  SS: 'South Sudan',
  ES: 'Spain',
  LK: 'Sri Lanka',
  SD: 'Sudan',
  SR: 'Suriname',
  SJ: 'Svalbard and Jan Mayen',
  SE: 'Sweden',
  CH: 'Switzerland',
  SY: 'Syria',
  TW: 'Taiwan',
  TJ: 'Tajikistan',
  TZ: 'Tanzania',
  TH: 'Thailand',
  TL: 'Timor-Leste',
  TG: 'Togo',
  TK: 'Tokelau',
  TO: 'Tonga',
  TT: 'Trinidad and Tobago',
  TN: 'Tunisia',
  TR: 'Turkey',
  TM: 'Turkmenistan',
  TC: 'Turks and Caicos Islands',
  TV: 'Tuvalu',
  UG: 'Uganda',
  UA: 'Ukraine',
  AE: 'United Arab Emirates',
  GB: 'United Kingdom',
  UM: 'United States Minor Outlying Islands',
  US: 'United States',
  UY: 'Uruguay',
  UZ: 'Uzbekistan',
  VU: 'Vanuatu',
  VE: 'Venezuela',
  VN: 'Vietnam',
  VG: 'Virgin Islands (British)',
  VI: 'Virgin Islands (U.S.)',
  WF: 'Wallis and Futuna',
  EH: 'Western Sahara',
  YE: 'Yemen',
  ZM: 'Zambia',
  ZW: 'Zimbabwe',
};

export const authErrorCodes = {
  'auth/wrong-password':
    'The email address or password is incorrect. Please try again.',
  'auth/claims-too-large':
    'The claims payload provided to setCustomUserClaims() exceeds the maximum allowed size of 1000 bytes.',
  'auth/email-already-exists':
    'The provided email is already in use by an existing user. Each user must have a unique email.',
  'auth/id-token-expired': 'The provided Firebase ID token is expired.',
  'auth/id-token-revoked': 'The Firebase ID token has been revoked.',
  'auth/insufficient-permission':
    'The credential used to initialize the Admin SDK has insufficient permission to access the requested Authentication resource. Refer to Set up a Firebase project for documentation on how to generate a credential with appropriate permissions and use it to authenticate the Admin SDKs.',
  'auth/invalid-argument':
    'An invalid argument was provided to an Authentication method. The error message should contain additional information.',
  'auth/invalid-claims':
    'The custom claim attributes provided to setCustomUserClaims() are invalid.',
  'auth/invalid-creation-time':
    'The creation time must be a valid UTC date string.',
  'auth/invalid-disabled-field':
    'The provided value for the disabled user property is invalid. It must be a boolean.',
  'auth/invalid-display-name':
    'The provided value for the displayName user property is invalid. It must be a non-empty string.',
  'auth/invalid-email-verified':
    'The provided value for the emailVerified user property is invalid. It must be a boolean.',
  'auth/invalid-hash-algorithm':
    'The hash algorithm must match one of the strings in the list of supported algorithms.',
  'auth/invalid-hash-block-size': 'The hash block size must be a valid number.',
  'auth/invalid-hash-derived-key-length':
    'The hash derived key length must be a valid number.',
  'auth/invalid-hash-key': 'The hash key must a valid byte buffer.',
  'auth/invalid-hash-memory-cost':
    'The hash memory cost must be a valid number.',
  'auth/invalid-hash-parallelization':
    'The hash parallelization must be a valid number.',
  'auth/invalid-hash-rounds': 'The hash rounds must be a valid number.',
  'auth/invalid-hash-salt-separator':
    'The hashing algorithm salt separator field must be a valid byte buffer.',
  'auth/invalid-id-token':
    'The provided ID token is not a valid Firebase ID token.',
  'auth/invalid-last-sign-in-time':
    'The last sign-in time must be a valid UTC date string.',
  'auth/invalid-page-token':
    'The provided next page token in listUsers() is invalid. It must be a valid non-empty string.',
  'auth/invalid-password':
    'The email address or password is incorrect. Please try again.',
  'auth/invalid-password-hash':
    'The password hash must be a valid byte buffer.',
  'auth/invalid-password-salt': 'The password salt must be a valid byte buffer',
  'auth/invalid-photo-url':
    'The provided value for the photoURL user property is invalid. It must be a string URL.',
  'auth/invalid-provider-data':
    'The providerData must be a valid array of UserInfo objects.',
  'auth/invalid-oauth-responsetype':
    'Only exactly one OAuth responseType should be set to true.',
  'auth/invalid-session-cookie-duration':
    'The session cookie duration must be a valid number in milliseconds between 5 minutes and 2 weeks.',
  'auth/invalid-uid':
    'The provided uid must be a non-empty string with at most 128 characters.',
  'auth/invalid-user-import': 'The user record to import is invalid.',
  'auth/maximum-user-count-exceeded':
    'The maximum allowed number of users to import has been exceeded.',
  'auth/missing-hash-algorithm':
    'Importing users with password hashes requires that the hashing algorithm and its parameters be provided.',
  'auth/missing-uid': 'A uid identifier is required for the current operation.',
  'auth/missing-oauth-client-secret':
    'The OAuth configuration client secret is required to enable OIDC code flow.',
  'auth/phone-number-already-exists':
    'The provided phoneNumber is already in use by an existing user. Each user must have a unique phoneNumber.',
  'auth/project-not-found':
    'No Firebase project was found for the credential used to initialize the Admin SDKs. Refer to Set up a Firebase project for documentation on how to generate a credential for your project and use it to authenticate the Admin SDKs.',
  'auth/reserved-claims':
    'One or more custom user claims provided to setCustomUserClaims() are reserved. For example, OIDC specific claims such as (sub, iat, iss, exp, aud, auth_time, etc) should not be used as keys for custom claims.',
  'auth/session-cookie-expired':
    'The provided Firebase session cookie is expired.',
  'auth/session-cookie-revoked':
    'The Firebase session cookie has been revoked.',
  'auth/uid-already-exists':
    'The provided uid is already in use by an existing user. Each user must have a unique uid.',
  'auth/admin-restricted-operation':
    'This operation is restricted to administrators only.',
  'auth/app-not-authorized': `This app, identified by the domain where it's hosted, is not authorized to use Firebase Authentication with the provided API key.Review your key configuration in the Google API console.`,
  'auth/app-not-installed':
    'The requested mobile application corresponding to the identifier (Android package name or iOS bundle ID) provided is not installed on this device.',
  'auth/captcha-check-failed':
    'The reCAPTCHA response token provided is either invalid, expired, already used or the domain associated with it does not match the list of whitelisted domains.',
  'auth/code-expired':
    'The SMS code has expired. Please re-send the verification code to try again.',
  'auth/cordova-not-ready': 'Cordova framework is not ready.',
  'auth/cors-unsupported': 'This browser is not supported.',
  'auth/credential-already-in-use':
    'This credential is already associated with a different user account.',
  'auth/custom-token-mismatch':
    'The custom token corresponds to a different audience.',
  'auth/requires-recent-login':
    'This operation is sensitive and requires recent authentication. Log in again before retrying this request.',
  'auth/dependent-sdk-initialized-before-auth':
    'Another Firebase SDK was initialized and is trying to use Auth before Auth is initialized. Please be sure to call `initializeAuth` or `getAuth` before starting any other Firebase SDK.',
  'auth/dynamic-link-not-activated':
    'Please activate Dynamic Links in the Firebase Console and agree to the terms and conditions.',
  'auth/email-change-needs-verification':
    'Multi-factor users must always have a verified email.',
  'auth/email-already-in-use':
    'The email address is already in use by another account.',
  'auth/expired-action-code': 'The action code has expired.',
  'auth/cancelled-popup-request':
    'This operation has been cancelled due to another conflicting popup being opened.',
  'auth/internal-error': 'An internal AuthError has occurred.',
  'auth/invalid-app-credential':
    'The phone verification request contains an invalid application verifier. The reCAPTCHA token response is either invalid or expired.',
  'auth/invalid-app-id':
    'The mobile app identifier is not registed for the current project.',
  'auth/invalid-user-token': `This user's credential isn't valid for this project. This can happen if the user's token has been tampered with, or if the user isn't for the project associated with this API key.`,
  'auth/invalid-auth-event': 'An internal AuthError has occurred.',
  'auth/invalid-verification-code':
    'The SMS verification code used to create the phone auth credential is invalid. Please resend the verification code sms and be sure to use the verification code provided by the user.',
  'auth/invalid-continue-uri':
    'The continue URL provided in the request is invalid.',
  'auth/invalid-cordova-configuration':
    'The following Cordova plugins must be installed to enable OAuth sign-in: cordova-plugin-buildinfo, cordova-universal-links-plugin, cordova-plugin-browsertab, cordova-plugin-inappbrowser and cordova-plugin-customurlscheme.',
  'auth/invalid-custom-token':
    'The custom token format is incorrect. Please check the documentation.',
  'auth/invalid-dynamic-link-domain':
    'The provided dynamic link domain is not configured or authorized for the current project.',
  'auth/invalid-email': 'The email address is badly formatted.',
  'auth/invalid-emulator-scheme':
    'Emulator URL must start with a valid scheme (http:// or https://).',
  'auth/invalid-api-key':
    'Your API key is invalid, please check you have copied it correctly.',
  'auth/invalid-cert-hash': 'The SHA-1 certificate hash provided is invalid.',
  'auth/invalid-credential':
    'The supplied auth credential is malformed or has expired.',
  'auth/invalid-message-payload':
    'The email template corresponding to this action contains invalid characters in its message. Please fix by going to the Auth email templates section in the Firebase Console.',
  'auth/invalid-multi-factor-session':
    'The request does not contain a valid proof of first factor greenful sign-in.',
  'auth/invalid-oauth-provider':
    'EmailAuthProvider is not supported for this operation. This operation only supports OAuth providers.',
  'auth/invalid-oauth-client-id':
    'The OAuth client ID provided is either invalid or does not match the specified API key.',
  'auth/unauthorized-domain':
    'This domain is not authorized for OAuth operations for your Firebase project. Edit the list of authorized domains from the Firebase console.',
  'auth/invalid-action-code':
    'The action code is invalid. This can happen if the code is malformed, expired, or has already been used.',
  'auth/invalid-persistence-type':
    'The specified persistence type is invalid. It can only be local, session or none.',
  'auth/invalid-phone-number':
    'The format of the phone number provided is incorrect. Please enter the phone number in a format that can be parsed into E.164 format. E.164 phone numbers are written in the format [+][country code][subscriber number including area code].',
  'auth/invalid-provider-id': 'The specified provider ID is invalid.',
  'auth/invalid-recipient-email':
    'The email corresponding to this action failed to send as the provided recipient email address is invalid.',
  'auth/invalid-sender':
    'The email template corresponding to this action contains an invalid sender email or name. Please fix by going to the Auth email templates section in the Firebase Console.',
  'auth/invalid-verification-id':
    'The verification ID used to create the phone auth credential is invalid.',
  'auth/invalid-tenant-id': `The Auth instance's tenant ID is invalid.`,
  'auth/missing-android-pkg-name':
    'An Android Package Name must be provided if the Android App is required to be installed.',
  'auth/auth-domain-config-required':
    'Be sure to include authDomain when calling firebase.initializeApp(), by following the instructions in the Firebase console.',
  'auth/missing-app-credential':
    'The phone verification request is missing an application verifier assertion. A reCAPTCHA response token needs to be provided.',
  'auth/missing-verification-code':
    'The phone auth credential was created with an empty SMS verification code.',
  'auth/missing-continue-uri':
    'A continue URL must be provided in the request.',
  'auth/missing-iframe-start': 'An internal AuthError has occurred.',
  'auth/missing-ios-bundle-id':
    'An iOS Bundle ID must be provided if an App Store ID is provided.',
  'auth/missing-or-invalid-nonce':
    'The request does not contain a valid nonce. This can occur if the SHA-256 hash of the provided raw nonce does not match the hashed nonce in the ID token payload.',
  'auth/missing-multi-factor-info': 'No second factor identifier is provided.',
  'auth/missing-multi-factor-session':
    'The request is missing proof of first factor greenful sign-in.',
  'auth/missing-phone-number':
    'To send verification codes, provide a phone number for the recipient.',
  'auth/missing-verification-id':
    'The phone auth credential was created with an empty verification ID.',
  'auth/app-deleted': 'This instance of FirebaseApp has been deleted.',
  'auth/multi-factor-info-not-found':
    'The user does not have a second factor matching the identifier provided.',
  'auth/multi-factor-auth-required':
    'Proof of ownership of a second factor is required to complete sign-in.',
  'auth/account-exists-with-different-credential':
    'An account already exists with the same email address but different sign-in credentials. Sign in using a provider associated with this email address.',
  'auth/network-request-failed':
    'A network AuthError (such as timeout, interrupted connection or unreachable host) has occurred.',
  'auth/no-auth-event': 'An internal AuthError has occurred.',
  'auth/no-such-provider':
    'User was not linked to an account with the given provider.',
  'auth/null-user':
    'A null user object was provided as the argument for an operation which requires a non-null user object.',
  'auth/operation-not-allowed':
    'The given sign-in provider is disabled for this Firebase project. Enable it in the Firebase console, under the sign-in method tab of the Auth section.',
  'auth/operation-not-supported-in-this-environment': `This operation is not supported in the environment this application is running on. 'location.protocol' must be http, https or chrome-extension and web storage must be enabled.`,
  'auth/popup-blocked':
    'Unable to establish a connection with the popup. It may have been blocked by the browser.',
  'auth/popup-closed-by-user':
    'The popup has been closed by the user before finalizing the operation.',
  'auth/provider-already-linked':
    'User can only be linked to one identity for the given provider.',
  'auth/quota-exceeded': `The project's quota for this operation has been exceeded.`,
  'auth/redirect-cancelled-by-user':
    'The redirect operation has been cancelled by the user before finalizing.',
  'auth/redirect-operation-pending':
    'A redirect sign-in operation is already pending.',
  'auth/rejected-credential':
    'The request contains malformed or mismatching credentials.',
  'auth/second-factor-already-in-use':
    'The second factor is already enrolled on this account.',
  'auth/maximum-second-factor-count-exceeded':
    'The maximum allowed number of second factors on a user has been exceeded.',
  'auth/tenant-id-mismatch': `The provided tenant ID does not match the Auth instance's tenant ID`,
  'auth/timeout': 'The operation has timed out.',
  'auth/user-token-expired': `The user's credential is no longer valid. The user must sign in again.`,
  'auth/too-many-requests':
    'We have blocked all requests from this device due to unusual activity. Try again later.',
  'auth/unauthorized-continue-uri':
    'The domain of the continue URL is not whitelisted.  Please whitelist the domain in the Firebase console.',
  'auth/unsupported-first-factor':
    'Enrolling a second factor or signing in with a multi-factor account requires sign-in with a supported first factor.',
  'auth/unsupported-persistence-type':
    'The current environment does not support the specified persistence type.',
  'auth/unsupported-tenant-operation':
    'This operation is not supported in a multi-tenant context.',
  'auth/unverified-email': 'The operation requires a verified email.',
  'auth/user-cancelled':
    'The user did not grant your application the permissions it requested.',
  'auth/user-not-found': `Couldn't find your Reel Account.`,
  'auth/user-disabled':
    'The user account has been disabled. Visit go.skuflic.com/account-disabled for more information.',
  'auth/user-mismatch':
    'The supplied credentials do not correspond to the previously signed in user.',
  'auth/weak-password': 'The password must be 6 characters long or more.',
  'auth/web-storage-unsupported':
    'This browser is not supported or 3rd party cookies and data may be disabled.',
  'auth/already-initialized':
    'initializeAuth() has already been called with different options. To avoid this error, call initializeAuth() with the same options as when it was originally called, or call getAuth() to return the already initialized instance.',
};
