// React
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { auth } from '../../firebase';

// Custom Functions
import { CustomNavbar } from '../../Components';

export default function Register() {
  const navigate = useNavigate();
  useEffect(() => {
    if (auth.currentUser) {
      navigate('/account');
    }
  }, []);

  document.title = `Create Your Account - Reel`;

  return (
    <>
      <div className='main'>
        <CustomNavbar />
        <div className='account'>
          <h1>Registration Disabled</h1>
          <p>
            Registration is currently disabled as we are updating the entire
            Reel experience. <br />
            Stay tuned for exciting improvements!
          </p>
        </div>
      </div>
    </>
  );
}
